import {
  defaultAttendanceScoreFeatureFlag,
  RELIABILITY_SCORE,
} from "@src/appV2/AttendanceScore/constants";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useClipboardScoreRollout() {
  return useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });
}

export function useAttendanceScorePolicyLinks() {
  return useCbhFlag(CbhFeatureFlag.ATTENDANCE_SCORE_POLICY_LINKS, {
    defaultValue: {},
  });
}

export function useScorePolicyLinks(config: { isReliabilityScoreEnabled?: boolean }) {
  const policyLinks = useAttendanceScorePolicyLinks();

  if (config.isReliabilityScoreEnabled) {
    policyLinks.howAttendanceWorks = {
      label: `How ${RELIABILITY_SCORE} Works`,
      url: "https://workers.clipboardhealth.com/hc/en-us/articles/30593458087703-Reliability-Rank-Introduction",
    };
  }

  return policyLinks;
}

export function useDisableSecondShiftBonus() {
  return useCbhFlag(CbhFeatureFlag.ATTENDANCE_SCORE_INFO, {
    defaultValue: defaultAttendanceScoreFeatureFlag,
  });
}

export function useClipboardScorePriorityAccessShiftsPerWeek() {
  return useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_PRIORITY_ACCESS_SHIFTS_PER_WEEK, {
    defaultValue: 0,
  });
}

export function useClipboardScoreLaunchDate() {
  return useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_LAUNCH_DATE, {
    defaultValue: "",
  });
}
