import { type CbhFeatureFlag } from "../FeatureFlags";
import { type CbhFeatureFlags } from "../FeatureFlags/CbhFeatureFlags";

export const defaultAttendanceScoreFeatureFlag: CbhFeatureFlags[CbhFeatureFlag.ATTENDANCE_SCORE_INFO] =
  {
    attendanceScoreInfo: false,
    lowAttendanceScoreBanner: false,
    disableSecondShiftBonus: false,
  };

export const DEFAULT_RESTRICTION_COUNT = 0;
export const DEFAULT_RESTRICTION_DURATION_IN_DAYS = 7;
export const DEFAULT_FINAL_RESTRICTION_DURATION = 365;
export const DEFAULT_FINAL_RESTRICTION_WINDOW = 6;
export const DEFAULT_FINAL_RESTRICTION_LIMIT = 3;
export const DEFAULT_NO_CALL_NO_SHOW_POINTS = -100;

export const DEFAULT_FINAL_RESTRICTION = {
  duration: DEFAULT_FINAL_RESTRICTION_DURATION,
  window: DEFAULT_FINAL_RESTRICTION_WINDOW,
  restriction: DEFAULT_FINAL_RESTRICTION_LIMIT,
};

export const DEFAULT_CLOCK_IN_BONUS_POINTS = 5;

export const ATTENDANCE_SCORE_THRESHOLD_FOR_LATENESS_WARNING = 70;

export const LATENESS_DEDUCTED_POINTS_THRESHOLD_FOR_WARNING = 30;

export const CLIPBOARD_SCORE = "Clipboard Score";
export const ATTENDANCE_SCORE = "Attendance Score";
export const RELIABILITY_SCORE = "Reliability Rank";
