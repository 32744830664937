import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { Box, Stack } from "@mui/material";
import { useWorkerPreferencesMutation } from "@src/appV2/Agents/api/useWorkerPreferences";
import { AgentPreferenceKey } from "@src/appV2/Agents/api/useWorkerPreferences/types";
import { getScoreColor } from "@src/appV2/AttendanceScore/utils";
import { getOrdinalPostfix } from "@src/appV2/lib/utils/formatNumbers";
import { GET_WORKER_PATH } from "@src/appV2/Worker/api/useGetWorker";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useQueryClient } from "@tanstack/react-query";
import { ceil } from "lodash";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { QualificationPreferencesBottomSheet } from "./QualificationPreferencesBottomSheet";

export interface ScorePageHeaderProps {
  score: number;
  rank?: number;
  totalWorkers?: number;
  showPercentile: boolean;
  workerType: string;
  isReliabilityScoreEnabled: boolean;
}

export function ScorePageHeader(props: ScorePageHeaderProps) {
  const { score, rank, totalWorkers, showPercentile, workerType, isReliabilityScoreEnabled } =
    props;

  const modalState = useModalState();
  const worker = useDefinedWorker();
  const queryClient = useQueryClient();
  const { mutateAsync: updatePreferences } = useWorkerPreferencesMutation();

  const percentile = useMemo(() => {
    if (isReliabilityScoreEnabled && isDefined(rank) && isDefined(totalWorkers)) {
      return Math.round((1 - rank / totalWorkers) * 100);
    }

    return null;
  }, [isReliabilityScoreEnabled, rank, totalWorkers]);

  const scoreOrPercentileValue = useMemo(() => {
    if (isReliabilityScoreEnabled && isDefined(percentile)) {
      return percentile;
    }

    return score;
  }, [isReliabilityScoreEnabled, percentile, score]);

  const mainScoreText = useMemo(() => {
    if (isReliabilityScoreEnabled && isDefined(percentile)) {
      return `${percentile}${getOrdinalPostfix(percentile)} percentile`;
    }

    return `${score} pts`;
  }, [isReliabilityScoreEnabled, percentile, score]);

  const licenses = worker.licensesData ?? [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
      }}
    >
      <Stack direction="column" gap={1}>
        <Text bold variant="h1" sx={{ color: getScoreColor(scoreOrPercentileValue) }}>
          {mainScoreText}
        </Text>
        {isReliabilityScoreEnabled && isDefined(percentile) && (
          <Text variant="subtitle2">Higher ranking professionals see shifts first</Text>
        )}
      </Stack>

      {isDefined(rank) && isDefined(totalWorkers) && (
        <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
          <EmojiEventsOutlinedIcon />
          <Stack direction="column">
            <Text bold>Local Rank</Text>
            <Text variant="subtitle2">
              {showPercentile ? (
                <>
                  Top {ceil(rank / totalWorkers, rank / totalWorkers < 0.1 ? 2 : 1) * 100}% of{" "}
                  {workerType}s
                </>
              ) : (
                <>
                  {rank}
                  {getOrdinalPostfix(rank)} of {totalWorkers}{" "}
                  {licenses.length > 1 ? (
                    <Link
                      to=""
                      onClick={(event) => {
                        event.preventDefault();
                        modalState.openModal();
                      }}
                    >
                      {workerType}s
                    </Link>
                  ) : (
                    ` ${workerType}s`
                  )}
                </>
              )}
            </Text>
          </Stack>
        </Stack>
      )}
      <QualificationPreferencesBottomSheet
        modalState={modalState}
        agentLicenses={licenses}
        defaultValues={{
          license: worker.preference?.qualification ?? "",
          distance: worker.preference?.distance ?? 0,
        }}
        onSubmit={async (values) => {
          await updatePreferences({
            key: AgentPreferenceKey.QUALIFICATION,
            value: values.license,
          });
          await queryClient.invalidateQueries({ queryKey: [GET_WORKER_PATH] });
        }}
      />
    </Box>
  );
}
